<template>
  <modal
    header-text=""
    button-text="Удалить"
    @action="delUser"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      Вы уверены, что хотите удалить пользователя?
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/control-modal.vue';
import { deleteControl } from '@/api/methods/control';

export default {
  components: { Modal },
  props: ['selected'],
  methods: {
    close() {
      this.$emit('close');
    },
    async delUser() {
      if (this.selected) {
        await deleteControl(this.selected.id);
        this.close();
        this.$emit('update');
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
</style>
