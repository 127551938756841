<template>
  <modal
    header-text="Добавление нового пользователя"
    button-text="Добавить"
    :class="$style.modal"
    @action="addUser()"
    @close="$emit('close')"
  >
    <div :class="$style.alert">
      {{ alert }}
    </div>
    <div :class="$style.inputContainer">
      <label :class="$style.label"> Логин</label>
      <input v-model="login" type="text" :class="$style.input">
    </div>

    <div :class="$style.inputContainer">
      <label :class="$style.label"> Пароль</label>
      <input v-model="password" type="password" :class="$style.input">
    </div>

    <div :class="$style.inputContainer">
      <label :class="$style.label"> Email</label>
      <input
        v-model="email"
        type="text"
        :class="$style.input"
        @blur="validate()"
      >
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import { addControl } from '@/api/methods/control';
import Modal from '@/components/common/modal/control-modal.vue';
import { encrypt } from '@/helpers/encryption';
import { validateEmail } from '@/helpers/methods';

import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: { Modal },
  data: () => ({
    alert: '',
    login: '',
    password: '',
    email: '',
  }),
  methods: {
    validate() {
      if (validateEmail(this.email)) {
        this.alert = '';
        return true;
      }
      this.alert = 'Пожалуйста, введите корректный email';
      return false;
    },
    close() {
      this.$emit('close');
    },
    async addUser() {
      if (this.login.length > 0) {
        if (this.validate()) {
          const result = await addControl(
            this.login,
            encrypt(this.password),
            this.email,
          );
          if (result === 'success') {
            this.login = '';
            this.password = '';
            this.email = '';
          }
          this.close();
          this.$emit('update');
        }
      } else {
        Vue.$toast.open({
          message: 'Введите логин',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.label {
  width: 40%;
}
.alert {
  padding: 5px;
  color: $red;
}
</style>
