<template>
  <article :class="$style.grid">
    <div
      :class="[$style.gridItem, $style.gridItemLeft, $style.gridItemComputers]"
    >
      <div :class="$style.gridItemComputersTable">
        <div :class="$style.gridItemComputersTableContent">
          <div :class="$style.buttonsContainer">
            <button
              :class="$style.addUserButton"
              @click="openModal('addControl')"
            >
              <div :class="$style.plus">
                +
              </div>
              Добавить
            </button>
          </div>
          <div :class="$style.gridItemComputersTableContentTable">
            <scrollable-container>
              <table :class="$style.controlsTable">
                <thead :class="$style.header">
                  <tr>
                    <th
                      v-for="column in columns"
                      :key="column.field"
                      :style="{ width: column.width }"
                      :class="[$style.cell]"
                    >
                      {{ column.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="control in controls"
                    :key="control.id"
                    :class="[
                      $style.row,
                      {
                        [$style.routerLinkActive]: selectedControl === control,
                      },
                    ]"
                    @click="redirect(control.id)"
                  >
                    <td
                      v-for="column in columns"
                      :key="column.field + control.id"
                      :class="$style.cell"
                    >
                      {{ getField(control[column.field], column.field) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </scrollable-container>
          </div>
        </div>
      </div>
    </div>
    <div :class="[$style.gridItem]">
      <div v-if="selectedControl" :class="$style.container">
        <div :class="$style.settingsHeader">
          <div :class="$style.title">
            {{ selectedControl.login }}
          </div>
          <button
            :class="[$style.delete, $style.button]"
            @click="openModal('deleteControl')"
          >
            Удалить
          </button>
        </div>
        <tabset
          class="contrTabs"
          :tabs="tabs"
          :opened="true"
          :hide-arrow="true"
          :class="$style.tabs"
          :tab-style="tabStyle"
          @tabClick="openNewTab"
        />
        <div v-show="tabs[0].active" :class="$style.container">
          <div :class="$style.inputContainer">
            <button
              :class="[$style.save, $style.button]"
              @click="changeControlParams()"
            >
              Сохранить
            </button>
          </div>
          <div :class="$style.container">
            <div :class="$style.card">
              <div :class="$style.alert">
                {{ alert }}
              </div>
              <div :class="$style.inputContainer">
                <label :class="$style.label"> Логин </label>
                <input
                  v-model="selectedControl.login"
                  type="text"
                  :class="$style.input"
                >
              </div>
              <div :class="$style.inputContainer">
                <label :class="$style.label"> Пароль </label>
                <input
                  v-model="selectedControl.password"
                  type="password"
                  :class="$style.input"
                >
              </div>
              <div :class="$style.inputContainer">
                <label :class="$style.label"> Email </label>
                <input
                  v-model="selectedControl.mail"
                  type="text"
                  :class="$style.input"
                  @blur="validate()"
                >
              </div>
            </div>
            <div v-if="isBotActive" :class="$style.card">
              <div :class="$style.tgInputContainer">
                <div :class="$style.tgStatus">
                  <div :class="[$style.tgClass, telegramClass]" />
                  <label v-if="isTelegramConnected" :class="$style.notifLabel">
                    Telegram-аккаунт привязан
                  </label>
                  <label v-if="!isTelegramConnected" :class="$style.notifLabel">
                    Telegram-аккаунт не привязан
                  </label>
                </div>
                <button
                  :class="[
                    $style.save,
                    $style.button,
                    $style.disconnectTgButton,
                  ]"
                  :disabled="!isTelegramConnected"
                  @click="disconnectTg()"
                >
                  Отвязать telegram-аккаунт
                </button>
              </div>
              <div
                :class="[$style.settContainer, $style.tgContainer]"
                @click="changeTwoFactorAuth"
              >
                <div :class="$style.notifLabel">
                  Включить двухфакторную аутентификацию
                </div>
                <toggle-button
                  v-model="twoFactorAuth"
                  :value="twoFactorAuth"
                  :sync="true"
                  :disabled="!isTelegramConnected"
                  @click.native.prevent
                />
              </div>
              <div :class="$style.tgInputContainer">
                <label :class="[$style.label, $style.labelTg]">
                  Ссылка для регистрации в Telegram-боте:
                </label>
                <div :class="$style.tgStatus">
                  <input
                    v-model="selectedControl.tgLink"
                    type="text"
                    disabled
                    :class="[$style.input, $style.readOnly, $style.tgLink]"
                  >
                  <img
                    title="Копировать ссылку"
                    src="@/assets/images/icons/navigation/copy.svg"
                    :class="$style.copyImg"
                    @click="copy"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="buildVersion === 0"
          v-show="tabs[1].active"
          :class="$style.container"
        >
          <div :class="$style.inputContainer">
            <button
              :class="[$style.save, $style.button]"
              @click="saveSubscriptions()"
            >
              Сохранить
            </button>
          </div>
          <div :class="$style.container">
            <div :class="[$style.card, $style.cardLines]">
              <label
                v-for="(value, index) in notifications.slice(0, 2)"
                :key="index"
                :class="$style.settContainer"
                @click="check(index)"
                @click.stop.prevent
              >
                <div :class="$style.notifLabel">
                  {{ value.name }}
                </div>
                <toggle-button
                  v-if="value.ttype"
                  v-model="value.checked"
                  :value="value.checked"
                  :sync="true"
                  @click.native.prevent
                />
                <toggle-button
                  v-else-if="value.htype"
                  v-model="value.checked"
                  :value="value.checked"
                  :sync="true"
                  @click.native.prevent
                />
              </label>
            </div>
            <div :class="[$style.card, $style.cardLines]">
              <label
                v-for="(value, index) in notifications.slice(2, 5)"
                :key="index"
                :class="$style.settContainer"
                @click="check(index + 2)"
                @click.stop.prevent
              >
                <div :class="$style.notifLabel">
                  {{ value.name }}
                </div>
                <toggle-button
                  v-if="value.ttype"
                  v-model="value.checked"
                  :value="value.checked"
                  :sync="true"
                  @click.native.prevent
                />
              </label>
            </div>
            <div :class="[$style.card, $style.cardLines]">
              <div :class="$style.notifTitle">
                Подписка на оповещение событий
              </div>
              <div
                v-for="(value, index) in notifications.slice(5)"
                :key="index"
                :class="$style.settContainer"
                @click="check(index + 5)"
              >
                <div :class="$style.notifLabel">
                  {{ value.name }}
                </div>

                <toggle-button
                  v-model="value.checked"
                  :value="value.checked"
                  :sync="true"
                  :disabled="
                    !notifications[2].checked &&
                      !notifications[3].checked &&
                      !notifications[4].checked
                  "
                  @click.native.prevent
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="tabs.find((tab) => tab.title === 'Настройка доступа').active"
          :class="$style.container"
        >
          <div :class="$style.inputContainer">
            <button
              :class="[$style.save, $style.button]"
              @click="saveAccessSettings()"
            >
              Сохранить
            </button>
          </div>
          <div :class="$style.container">
            <div :class="$style.card">
              <div :class="$style.inputContainer">
                <div :class="$style.label">
                  Тип доступа
                </div>
                <div class="controlSelect">
                  <vue-select
                    v-model="selectedType"
                    :options="accessType"
                    :class="$style.select"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :searchable="false"
                    :disabled="selectedControl.id === 1"
                    @input="changeAccess"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      Нет данных для выбора
                    </template>
                  </vue-select>
                </div>
              </div>
              <div :class="$style.inputContainer">
                <div :class="$style.label">
                  Уровень доступа
                </div>
                <div class="controlSelect">
                  <vue-select
                    v-model="selectedLevel"
                    :options="accessLevel"
                    :class="$style.select"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :searchable="false"
                    :disabled="selectedType !== 10"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      Нет данных для выбора
                    </template>
                  </vue-select>
                </div>
              </div>
            </div>
            <div :class="$style.card">
              <div :class="$style.selectLabel">
                Доступные для просмотра категории:
              </div>
              <div class="compTree">
                <treeselect
                  v-model="selectedLogs"
                  :disabled="selectedType !== 10"
                  :multiple="true"
                  :options="accessLogs"
                  :placeholder="placeholder"
                  :clearable="true"
                  :searchable="true"
                  :open-on-click="true"
                  open-direction="below"
                />
              </div>
              <div :class="$style.selectLabel">
                Доступные для просмотра объекты:
              </div>
              <div class="compTree">
                <treeselect
                  v-model="selectedComputers"
                  :disabled="selectedType !== 10"
                  :multiple="true"
                  :options="options"
                  :placeholder="placeholder"
                  :clearable="true"
                  :searchable="true"
                  :open-on-click="true"
                  :no-children-text="'Подразделение пусто'"
                  open-direction="below"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <img
                      v-if="node.isBranch"
                      class="iconFolder"
                      src="@/assets/images/icons/computer/folder.svg"
                    >
                    {{ node.label }}
                  </label>
                </treeselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <delete-control-modal
      id="deleteControl"
      :class="$style.modal"
      :selected="selectedControl"
      @close="closeModal('deleteControl')"
      @update="fetch()"
    />
    <add-control
      id="addControl"
      :class="$style.modal"
      @close="closeModal('addControl')"
      @update="fetch()"
    />
    <save-settings-modal
      id="saveSettings"
      :class="$style.modal"
      :name="selectedControl"
      @close="closeModal('saveSettings')"
      @save="saveSettings()"
      @dontSave="discardChanges()"
    />
  </article>
</template>

<script>
/* eslint-disable prefer-destructuring */
import Vue from 'vue';
import VueSelect from 'vue-select';
import VueToast from 'vue-toast-notification';
import { ToggleButton } from 'vue-js-toggle-button';
import Treeselect from '@riophae/vue-treeselect';
import {
  getControls,
  changeParams,
  getSubscriptions,
  changeSubscriptions,
  getStatus,
  changeAccessSettings,
  editTwoFactorAuth,
  checkBot,
  disconnectTelegram,
} from '@/api/methods/control';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import DeleteControlModal from '@/views/settings/control/deleteControl.vue';
import SaveSettingsModal from '@/views/settings/control/saveSettings.vue';
import { encrypt } from '@/helpers/encryption';
import 'vue-toast-notification/dist/theme-default.css';
import AddControl from '@/views/settings/control/addControl.vue';
import { validateEmail } from '@/helpers/methods';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'vue-select/src/scss/vue-select.scss';

Vue.component('ToggleButton', ToggleButton);

Vue.use(VueToast);

export default {
  components: {
    ScrollableContainer,
    Tabset,
    DeleteControlModal,
    AddControl,
    VueSelect,
    Treeselect,
    SaveSettingsModal,
  },
  data: () => ({
    isBotActive: false,
    accessLogs: [
      { id: 1, label: 'Клавиатура' },
      { id: 2, label: 'Скриншоты' },
      { id: 3, label: 'Программы' },
      { id: 4, label: 'Файлы' },
      { id: 5, label: 'Буфер обмена' },
      { id: 6, label: 'Принтер' },
      { id: 7, label: 'Установленные программы' },
      { id: 8, label: 'Внешние накопители' },
      { id: 10, label: 'Посещенные сайты' },
      { id: 11, label: 'Компьютер' },
      { id: 14, label: 'Теневое копирование' },
      { id: 15, label: 'Почта' },
      { id: 18, label: 'Сеть:Доступ' },
      { id: 19, label: 'Сеть:Файлы' },
      { id: 20, label: 'Web почта' },
      { id: 21, label: 'Выгрузка файлов' },
      { id: 22, label: 'Социальные сети' },
      { id: 23, label: 'Мессенджеры' },
      { id: 24, label: 'Webcam/Microphone' },
      { id: 25, label: 'Изменение оборудования' },
      { id: 26, label: 'Поисковые запросы' },
    ],
    selectedComputers: [],
    selectedLogs: null,
    allStartComputers: [],
    allCurrentComputers: [],
    placeholder: '',
    options: [],
    selectedLevel: undefined,
    selectedType: undefined,
    accessType: [
      { id: 0, name: 'Неактивен' },
      { id: 10, name: 'Пользователь' },
      { id: 1, name: 'Администратор' },
    ],
    accessLevel: [
      { id: 2, name: 'Только чтение' },
      { id: 3, name: 'Чтение и удаление' },
      { id: 4, name: 'Администрирование агентов' },
    ],
    startAccess: undefined,
    startAccessLogs: [],
    startAccessComps: [],
    alert: '',
    notifications: [
      { name: 'Активное оповещение', htype: 12, ttype: 14, checked: false },
      {
        name: 'Оповещать о нестандартном поведении',
        htype: null,
        ttype: 28,
        checked: false,
      },
      { name: 'Уведомлять напрямую', htype: null, ttype: 1, checked: false },
      { name: 'Уведомлять по email', htype: null, ttype: 2, checked: false },
      { name: 'Уведомлять по Telegram', htype: null, ttype: 3, checked: false },

      { name: 'Клавиатура', htype: 1, ttype: null, checked: false },
      { name: 'Программы', htype: 2, ttype: null, checked: false },
      { name: 'Файлы', htype: 3, ttype: null, checked: false },
      { name: 'Буфер обмена', htype: 4, ttype: null, checked: false },
      { name: 'Принтер', htype: 5, ttype: null, checked: false },
      {
        name: 'Установка/удаление программ',
        htype: 6,
        ttype: null,
        checked: false,
      },
      { name: 'Посещенные сайты', htype: 7, ttype: null, checked: false },
      { name: 'Мессенджеры', htype: 8, ttype: null, checked: false },
      { name: 'Теневое копирование', htype: 10, ttype: null, checked: false },
      { name: 'Почта', htype: 13, ttype: null, checked: false },
      { name: 'Сеть: Файлы', htype: 17, ttype: null, checked: false },
      { name: 'Web почта', htype: 21, ttype: null, checked: false },
      { name: 'Выгрузка файлов', htype: 22, ttype: null, checked: false },
      {
        name: 'Изменение оборудования',
        htype: 26,
        ttype: null,
        checked: false,
      },
      { name: 'Поисковые запросы', htype: 27, ttype: null, checked: false },
      { name: 'Работа ПК ', htype: 28, ttype: null, checked: false },
      { name: 'Новый ПК из AD ', htype: 31, ttype: null, checked: false },
    ],
    startNotifications: [],
    initial: [],
    controls: [],
    columns: [
      { name: 'Название', field: 'login', width: '40%' },
      { name: 'Email', field: 'mail', width: '30%' },
      { name: 'Статус', field: 'status', width: '30%' },
    ],
    tabs: [
      {
        title: 'Параметры',
        active: true,
      },
      {
        title: 'Оповещения',
        active: false,
      },
      {
        title: 'Настройка доступа',
        active: false,
      },
    ],
    tabStyle: {
      fontSize: '15px',
    },
    targetControlId: null,
    buildVersion: 0,
  }),
  computed: {
    telegramClass() {
      if (this.isTelegramConnected) return this.$style.green;
      return this.$style.red;
    },
    isTelegramConnected() {
      if (this.selectedControl.tgChatId.length > 0) return true;
      return false;
    },
    twoFactorAuth: {
      get() {
        if (this.selectedControl.useTwoFactorAuth === 1) return true;
        return false;
      },
      set(val) {
        this.selectedControl.useTwoFactorAuth = val;
      },
    },
    selectedControl() {
      let activeItem;
      if (this.$route.params.user && this.controls.length > 0) {
        const id = parseInt(this.$route.params.user, 10);
        activeItem = this.controls.find((i) => i.id === id);
      }

      if (activeItem) {
        return activeItem;
      }
      return null;
    },
    settingsChanged() {
      const subs = this.getChangedSubs();
      const initialControl = this.initial.find(
        (i) => i.id === this.selectedControl.id,
      );
      if (
        subs.length > 0
        || (this.startAccess !== this.selectedType
          && this.startAccess !== this.selectedLevel)
        || !this.compareArrays(this.startAccessLogs, this.selectedLogs)
        || !this.compareArrays(this.startAccessComps, this.selectedComputers)
        || this.selectedControl.mail !== initialControl.mail
        || this.selectedControl.login !== initialControl.login
        || this.selectedControl.password !== initialControl.password
      ) return true;
      return false;
    },
  },
  watch: {
    '$route.params.user': async function () {
      await this.getSubscriptions();
      await this.getAccessData();
    },
  },
  async created() {
    this.buildVersion = this.$store.getters['user/buildVersion'];
    if (this.buildVersion !== 0) {
      this.tabs = [
        {
          title: 'Параметры',
          active: true,
        },
        {
          title: 'Настройка доступа',
          active: false,
        },
      ];
    }
    await this.fetch();
    if (this.selectedControl) {
      await this.getSubscriptions();
      await this.getAccessData();
    }
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/refresh') {
          await this.fetch();
        }
      },
    });
  },
  methods: {
    async disconnectTg() {
      await disconnectTelegram(this.selectedControl.id);
      await this.fetch();
    },
    async checkBot() {
      const result = await checkBot();
      this.isBotActive = result.bot;
    },
    async changeTwoFactorAuth() {
      if (this.isTelegramConnected) {
        if (this.twoFactorAuth) {
          this.twoFactorAuth = 0;
          await editTwoFactorAuth(this.selectedControl.id, 0);
        } else {
          this.twoFactorAuth = 1;
          await editTwoFactorAuth(this.selectedControl.id, 1);
        }
      }
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.selectedControl.tgLink);
        Vue.$toast.open({
          message: 'Ссылка скопирована',
          type: 'success',
        });
      } catch ($e) {
        Vue.$toast.open({
          message: 'Не удалось скопировать ссылку',
          type: 'error',
        });
      }
    },
    compareArrays(array1, array2) {
      const array2Sorted = array2.slice().sort();
      return (
        array1.length === array2.length
        && array1
          .slice()
          .sort()
          .every((value, index) => value === array2Sorted[index])
      );
    },
    redirect(id) {
      if (!this.selectedControl) {
        this.$router.push({
          name: 'controls-control',
          params: { user: id },
        });
      } else if (this.selectedControl.id !== id) {
        if (this.settingsChanged) {
          this.targetControlId = id;
          this.openModal('saveSettings');
        } else {
          this.$router.push({
            name: 'controls-control',
            params: { user: id },
          });
        }
      }
    },
    async saveSettings() {
      await this.changeControlParams();
      await this.saveSubscriptions();
      await this.saveAccessSettings();
      this.closeModal('saveSettings');
      this.$router.push({
        name: 'controls-control',
        params: { user: this.targetControlId },
      });
    },
    async discardChanges() {
      this.closeModal('saveSettings');
      this.$router.push({
        name: 'controls-control',
        params: { user: this.targetControlId },
      });
      await this.fetch();
    },
    changeAccess(val) {
      if (val === 0) {
        this.selectedComputers = [];
        this.selectedLogs = [];
        this.selectedLevel = null;
      } else if (val === 1) {
        this.selectedLogs = [];
        this.accessLogs.forEach((log) => this.selectedLogs.push(log.id));
        this.selectedComputers = [];
        this.options.forEach((option) => this.selectedComputers.push(option.id));
        this.selectedLevel = null;
      } else if (!this.selectedLevel) this.selectedLevel = 2;
    },
    async saveAccessSettings() {
      let accessStatus = null;
      this.allStartComputers = [];
      this.allCurrentComputers = [];
      if (
        this.startAccess !== this.selectedType
        || this.startAccess !== this.selectedLevel
      ) {
        if (this.selectedType === 10) {
          if (!this.selectedLevel) this.selectedLevel = 2;
          accessStatus = this.selectedLevel;
        } else accessStatus = this.selectedType;
      }
      const logsToDelete = this.startAccessLogs.filter(
        (x) => !this.selectedLogs.includes(x),
      );
      const logsToAdd = this.selectedLogs.filter(
        (x) => !this.startAccessLogs.includes(x),
      );

      this.startAccessComps.forEach((comp) => {
        const found = this.recursivelyFindKeyValue(comp, this.options);
        if (found.found) this.getAllComputers(found.res, this.allStartComputers);
      });

      this.selectedComputers.forEach((comp) => {
        const found = this.recursivelyFindKeyValue(comp, this.options);
        if (found.found) this.getAllComputers(found.res, this.allCurrentComputers);
      });

      const compsToDelete = this.allStartComputers.filter(
        (x) => !this.allCurrentComputers.includes(x),
      );
      const compsToAdd = this.allCurrentComputers.filter(
        (x) => !this.allStartComputers.includes(x),
      );

      await changeAccessSettings(
        this.selectedControl.id,
        accessStatus,
        logsToAdd,
        logsToDelete,
        compsToAdd,
        compsToDelete,
      );

      await this.fetch();
      await this.getAccessData();
    },
    recursivelyFindKeyValue(keyValue, list) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];

        if (item.children) {
          const res = this.recursivelyFindKeyValue(keyValue, item.children);
          if (res.found === true) return res;
        }

        if (item.id === keyValue) {
          return { found: true, res: item };
        }
      }

      return { found: false, res: null };
    },
    getAllComputers(node, result) {
      result.push(node);
      if (node.type === 0) {
        node.children.forEach((child) => this.getAllComputers(child, result));
      }
    },
    async getAccessData() {
      if (this.selectedControl) {
        const access = await getStatus(this.selectedControl.id);
        this.options = access.accessTree;
        const selected = [];
        this.startAccessComps = [];
        this.startAccess = access.status;
        if (access.status < 2) {
          this.selectedType = access.status;
        }
        if (access.status === 1) {
          this.accessLogs.forEach((log) => selected.push(log.id));
          this.options.forEach((option) => this.startAccessComps.push(option.id));
        } else if (access.status !== 0) {
          this.selectedType = 10;
          this.selectedLevel = access.status;
          if (access.logs) {
            access.logs.forEach((log) => {
              const found = this.accessLogs.find((item) => item.id === log.id);
              if (found) selected.push(found.id);
            });
          }
          if (access.accessTree) {
            access.accessTree.forEach((node) => this.getCheckedComputers(node));
          }
        }
        this.selectedLogs = selected;
        this.startAccessLogs = selected;
        this.selectedComputers = this.startAccessComps;
      }
    },
    getCheckedComputers(node) {
      if (node.link > 0) this.startAccessComps.push(node.id);
      else if (node.children) node.children.forEach((child) => this.getCheckedComputers(child));
    },
    validate() {
      if (validateEmail(this.selectedControl.mail)) {
        this.alert = '';
        return true;
      }
      this.alert = 'Пожалуйста, введите корректный email';
      return false;
    },
    getChangedSubs() {
      const result = [];
      for (let i = 0, len = this.notifications.length; i < len; i++) {
        if (
          this.notifications[i].checked !== this.startNotifications[i].checked
        ) {
          result.push(this.notifications[i]);
        }
      }
      return result;
    },
    async saveSubscriptions() {
      const changed = this.getChangedSubs();
      await changeSubscriptions(changed, this.selectedControl.id);
      await this.getSubscriptions();
    },
    check(key) {
      if (
        key < 5
        || this.notifications[2].checked
        || this.notifications[3].checked
        || this.notifications[4].checked
      ) {
        this.$set(
          this.notifications[key],
          'checked',
          !this.notifications[key].checked,
        );
      }
    },
    async getSubscriptions() {
      if (this.selectedControl) {
        this.notifications.forEach((n) => {
          n.checked = false;
        });
        this.startNotifications = [];
        const subscr = await getSubscriptions(this.selectedControl.id);
        if (subscr.length > 0) {
          subscr.forEach((sub) => {
            const ttype = this.notifications.find((i) => i.ttype === sub.ttype);
            if (ttype) ttype.checked = true;
            else {
              const htype = this.notifications.find(
                (i) => i.htype === sub.htype,
              );
              if (htype) htype.checked = true;
            }
          });
        }

        this.startNotifications = JSON.parse(
          JSON.stringify(this.notifications),
        );
      }
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async changeControlParams() {
      if (this.selectedControl) {
        let mail = null;
        let login = null;
        let password = null;
        let existed = null;
        let valid = true;
        const initialControl = this.initial.find(
          (i) => i.id === this.selectedControl.id,
        );
        if (initialControl) {
          if (this.selectedControl.mail !== initialControl.mail) {
            if (this.validate()) mail = this.selectedControl.mail;
            else valid = false;
          }

          if (this.selectedControl.login !== initialControl.login) {
            existed = this.initial.find(
              (i) => i.login === this.selectedControl.login,
            );
            if (!existed) login = this.selectedControl.login;
            else {
              Vue.$toast.open({
                message: 'Пользователь с таким логином уже существует',
                type: 'error',
              });
            }
          }

          if (this.selectedControl.password !== initialControl.password) {
            password = encrypt(this.selectedControl.password, this.key_pass);
          }

          if (!existed && valid) {
            if (!login && password) login = this.selectedControl.login;
            if (login && !password) password = this.selectedControl.password;
            await changeParams(this.selectedControl.id, login, password, mail);
            await this.fetch();
          }
        }
      }
    },
    async fetch() {
      await this.checkBot();
      this.controls = [];
      this.initial = [];
      this.controls = await getControls();
      this.initial = JSON.parse(JSON.stringify(this.controls));
    },
    getField(value, field) {
      if (field === 'status') {
        switch (value) {
          case 0:
            return 'Неактивен';
          case 1:
            return 'Администратор';
          case 2:
            return 'Только чтение';
          case 3:
            return 'Чтение и удаление';
          case 4:
            return 'Администрирование агентов';

          default:
            return '';
        }
      } else return value;
    },
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
  },
};
</script>

<style lang="scss" module>
.select {
  width: 60%;
  // margin: 20px;
}

.alert {
  padding: 5px;
  color: $red;
  font-size: 13px;
}

.grid {
  border-left: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  display: flex;
  background: white;
  height: 100%;

  overflow: auto;
  display: flex;
}

.gridItem {
  flex: 0 0 55%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.gridItemLeft {
  flex: 0 0 45%;
}

.gridItemComputers {
  display: flex;
}

.gridItemComputersTable {
  flex: 1 1 100%;
  height: 100%;
}

.gridItemComputersTableContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid $borderColor;
}

.gridItemComputersTableContentTable {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: inline-flex;
  width: 100%;
}

.addUserButton {
  border: 1px solid white;
  background: #2985bf;
  cursor: pointer;
  height: 30px;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 30px;
  width: 88px;
  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}
.delete {
  background: $red;
}
.save {
  background: #2985bf;
}
.disconnectTgButton {
  width: 188px;
}

.cell {
  padding: 15px 10px 15px 25px;
  text-align: left;
  font-size: 13px;
}

.header {
  background: #f5f5f5;
  height: 31px;
  position: sticky;
  top: 0px;
  z-index: 3;
  .cell {
    font-weight: 600;
  }
}

.row {
  border-bottom: 1px solid #eee;
  &:hover {
    background: #f5f5f5;
    transition: background-color 0.5s linear;
    cursor: pointer;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.routerLinkActive {
  background-color: $light-gray;
}

.settingsHeader {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.plus {
  font-weight: 500;
  font-size: 24px;
  padding: 0px 5px;
}

.tabs {
  margin: 10px;
}

.input {
  margin: 5px;
  width: 40%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 20px;
  display: flex;
  align-items: center;
}
.label {
  width: 20%;
  margin-left: 10px;
}

.selectLabel {
  margin: 20px 20px 0px 20px;
  font-size: 13px;
}
.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
}
.cardLines {
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.modal {
  display: none;
  z-index: 3;
}

.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  // width: 90%;
  align-items: center;
  cursor: pointer;
  // margin-left: 10px;
  &:hover {
    background: $light-gray;
  }
}

.notifLabel {
  margin: 0px 10px;
  font-size: 14px;
}

.notifTitle {
  margin: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  border: 0px !important;
}

.container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.readOnly {
  border: 0px;
  background: transparent;
}
.readOnly :focus {
  outline: none !important;
}

.labelTg {
  width: 40%;
}

.copyImg {
  cursor: pointer;
}

.tgContainer {
  margin: 20px;
}

.tgClass {
  flex: 0 0 12px;
  height: 12px;
  margin: 6px 0 6px 10px;
  border-radius: 50%;

  &.green {
    background: $green;
  }

  &.red {
    background: $red;
  }
}

.tgStatus {
  display: flex;
  width: 50%;
  align-items: center;
}

.tgInputContainer {
  font-size: 13px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tgLink {
  width: 100%;
}
</style>

<style>
.contrTabs .single-view-tabs_tabs_2UQRS .single-view-tabs_link_3Sq1C {
  @media (max-width: 1831px) {
    font-size: 15px !important;
  }
}

.compTree .vue-treeselect__control {
  border-radius: 0px;
}
.compTree .vue-treeselect__multi-value {
  margin: 3px 0px;
}
.compTree .vue-treeselect__placeholder {
  padding-top: 7px;
}
.compTree .vue-treeselect__multi-value-item-container {
  padding-top: 3px;
}
.compTree .iconFolder {
  display: inline-flex;
}
.compTree .vue-treeselect__checkbox--checked {
  background-color: #2985bf;
  border: 1px solid #2985bf;
}
.compTree .vue-treeselect__check-mark {
  margin: 0.5px;
}
.compTree .vue-treeselect {
  margin: 10px 20px 20px 20px;
}

.controlSelect {
  width: 100%;
}
.controlSelect .v-select .vs__dropdown-toggle {
  height: 36px;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
}
.controlSelect .v-select .vs__selected-options {
  padding: 4px 0 0 9px;
}
.controlSelect .v-select .vs__selected-options .vs__selected {
  line-height: 20px;
}
.controlSelect .v-select ul {
  width: -webkit-fill-available;
  font-size: 14px;
}
.controlSelect .v-select ul > li {
  width: auto;
}
.controlSelect .vs__selected {
  margin: 4px 2px 3px 2px;
  font-size: 14px;
}
.vs__dropdown-option--highlight {
  background: #f5f5f5;
  color: black;
}
</style>
